import React from "react";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";
//import { LOGOUT_ACTION } from "../../../store/actions/AuthActions"
export const MenuList = [
  //Dashboard
  {
     title: 'Dashboard',
   classsChange: 'mm-collapse',
     iconStyle: <i className="material-icons">grid_view</i>,
     to: 'dashboard',
   
  },
    
  // {
  //   title: " User Profiles",
  //   //classsChange: 'mm-collapse',
  //   // iconStyle: Widget,
  //   iconStyle: <i className="material-icons text-white">account_circle</i>,
  //   to: "allUsers",
  // },
  {
    title: " Stking User",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "staking-user",
  },
  {
    title: " User Details",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "filterRecord",
  },
  // {

  {
    title: "Withdraw Confirm ",
    //classsChange: 'mm-collapse',
    //iconStyle: Widget,
    iconStyle: <i className="material-icons">account_circle</i>,
    to: "withdRawConfirm",
  },

  {
    title:'Withdraw Approved ',
    //classsChange: 'mm-collapse',
    //iconStyle: Widget,
    iconStyle: <i className="material-icons">account_circle</i>,
    to: 'withdraw-approve',   //roi-approve
},
{
  title:'CT0 Achiever Details ',
  //classsChange: 'mm-collapse',
  //iconStyle: Widget,
  iconStyle: <i className="material-icons">account_circle</i>,
  to: 'cto-acheiver',   //roi-approve
},
{
    title: "Withdraw Reject",
    //classsChange: 'mm-collapse',
    // iconStyle: Widget,
    iconStyle: <i className="material-icons text-white">account_circle</i>,
    to: "reject-withdraw",
  },


  {
    title: "Logout",
    // classsChange: 'mm-collapse',
    iconStyle: <i class="material-icons"> logout </i>,
    onClick: Logout,
  },
];
