import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Table } from "react-bootstrap";
import { allUser } from "../../../services/api_function";
import Web3 from "web3";
import { transfer_abi, transfer_addres } from "../../config/config";

export const NewRecord = () => {
  const [apiData, setApiData] = useState([]);
  const [currentPrice, setCurrentPrice] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [planFilter, setPlanFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pageSize = 20;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;

        const filters = {
          searchQuery: search,
          plan: planFilter,
          status: statusFilter,
        };

        const result = await allUser(currentPage, filters, token, planFilter, statusFilter);
        setApiData(result.data);
        setFilteredData(result.data);
        const total = result.totalUsers;
        const pages = Math.ceil(total / pageSize);
        setTotalPages(pages > 0 ? pages : 1);

        if (result.status === 404) {
          navigate("/login");
          localStorage.removeItem("userDetails");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage, search, planFilter, statusFilter]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleSearch = (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "planFilter") {
      setPlanFilter(value);
    } else if (name === "statusFilter") {
      setStatusFilter(value);
    }

    // Reset to the first page whenever a filter is applied
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  async function getPrice() {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(transfer_abi, transfer_addres);
      const reslut = await contract.methods.getCurrentPrice(["0xE0eAe74BEc76696Cc82dF119Ea35653506D54155", "0x55d398326f99059fF775485246999027B3197955"]).call();
      return Number(reslut);
    } catch (e) {
      console.log("coming catch");
      console.log(e, "e from contract register");
      return false;
    }
  }

  useEffect(() => {
    getPrice().then((res) => {
      setCurrentPrice(res / 1e18);
      console.log(res, "resss");
    });
  }, []);

  // Calculate the sums of Stake, Earn, Assured, and Live Rate Token Qty
  const sumData = filteredData.reduce(
    (acc, user) => {
      const stake = user?.stake_amount ?? 0;
      const earn = user?.totalIncome ?? 0;
      const assured = (stake - earn / 2).toFixed(2);
      const liveRateTokenQty = ((stake - earn / 2) / currentPrice).toFixed(2);
      
      acc.stake += stake;
      acc.earn += earn;
      acc.assured += parseFloat(assured);
      acc.liveRateTokenQty += parseFloat(liveRateTokenQty);
      
      return acc;
    },
    { stake: 0, earn: 0, assured: 0, liveRateTokenQty: 0 }
  );

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group m-2" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div>
        </div>

        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                All Users
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                background: "black",
                border: "1px solid white",
                borderRadius: "3px",
              }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "0.5px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>NO.</strong>
                    </th>
                    <th>
                      <strong>UserID</strong>
                    </th>
                    <th>
                      <strong>Stake</strong>
                    </th>
                    <th>
                      <strong>Earn</strong>
                    </th>
                    <th>
                      <strong>Assured</strong>
                    </th>
                    <th>
                      <strong>pp value</strong>
                    </th>
                    <th>
                      <strong>Assured</strong>
                    </th>
                    <th>
                      <strong>Token Qty</strong>
                    </th>
                    <th>
                      <strong>Coin Qty</strong>
                    </th>
                    <th>
                      <strong>Date&Time</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map((user, index) => (
                    <tr key={index}>
                      <td>{(currentPage - 1) * pageSize + index + 1}</td>
                      <td>{user.userId}</td>
                      <td>{(user?.stake_amount ?? 0).toFixed(2)}</td>
                      <td>{(user?.totalIncome ?? 0).toFixed(2)}</td>
                      <td>{(user?.capping ?? 0)} x</td>
                      <td>{(user?.totalIncome ?? 0).toFixed(2) / 2}</td>
                      <td>
                        {((user?.stake_amount ?? 0) - (user?.totalIncome ?? 0) / 2).toFixed(2)}
                      </td>
                      <td>
                        {(((user?.stake_amount ?? 0) - (user?.totalIncome ?? 0) / 2) / currentPrice).toFixed(2)}
                      </td>
                      <td>
                        {((((user?.stake_amount ?? 0) - (user?.totalIncome ?? 0) / 2) / currentPrice) / 30).toFixed(2)}
                      </td>
                      <td>{formatTimestamp(user.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}><strong>Totals</strong></td>
                    <td>{sumData.stake.toFixed(2)}</td>
                    <td>{sumData.earn.toFixed(2)}</td>
                    <td>{sumData.assured.toFixed(2)}</td>
                    <td>{sumData.liveRateTokenQty.toFixed(2)}</td>
                    <td colSpan={3}></td>
                  </tr>
                </tfoot>
              </Table>

              {/* Pagination */}
              <div className="d-flex justify-content-between">
                <span>
                  <strong></strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {"<<"}
                  </button>
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        "linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {">>"}
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NewRecord;
